@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Exo2.0-Regular";
    src: /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./font/Exo2.0/Exo2.0-Regular.otf") format("opentype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: "HanSerifSC-Regular";
    src: /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./font/HanSerifSC-v2/SourceHanSerifSC-Medium.otf") format("opentype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: "Great-Vibes-Regular";
    src: /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./font/great-vibes.regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: "AlegreSans";
    src: /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./font/AlegreSans-Regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: "Gyreadventor";
    src: /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./font/bodoni-six-itc-bold-italic-os-5871d33e4dc4a.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 5px;
        /* height: 20px; */
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f7f4ed;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #a5a5a5;
        border-radius: 100vh;

        border: 1px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #c0a0b9;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 100;
    }

    100% {
        opacity: 0;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100;
    }
}
/* change transition duration to control the speed of fade effect */
/* .carousel-item {
    transition: transform 1s ease-in-out;
} */
/* .slide .active.carousel-item-end {
    animation: fadeIn 1s ease-in;
} */
.slide .active.carousel-item-start,
.slide .active.carousel-item-end {
    animation: fadeOut 0.6s ease-out;
}

.ant-carousel .slick-dots li button,
.ant-carousel .slick-dots li.slick-active button {
    background-color: black;
}

/* ant-image */
.rounded_image_mask .ant-image-mask {
    border-radius: 0.75rem;
}

/* Affix */
@keyframes scaleUp {
    0% {
        transform: scale(0.9, 0.9);
    }

    50% {
        transform: scale(1.25, 1.25);
    }

    100% {
        transform: scale(1, 1);
    }
}

.scaleUp {
    animation-name: scaleUp;
    animation-timing-function: ease;
    animation-duration: 0.5s;
}

.whatsAppButton {
    outline: none;
    border: 0;
    background-color: transparent;
    width: 75px;
    height: 75px;
}

.whatsAppImg {
    width: 110%;
}

.ring-container {
    position: relative;
}

.circleSuccess {
    width: 13px;
    height: 13px;
    background-color: #03c04a;
    border-radius: 50%;
    position: absolute;
    top: 23px;
    left: 23px;
}

.ringringSuccess {
    border: 3px solid #03c04a;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    width: 75px;
    height: 75px;
    position: absolute;
    left: 17px;
    top: 17px;
    animation: none;
    -webkit-animation: pulsate 2s ease-out;
    animation-iteration-count: unset;
    -webkit-animation-iteration-count: infinite;
    opacity: 0;
}

@keyframes pulsate {
    0% {
        -webkit-transform: scale(1, 1);
        opacity: 1;
    }

    40% {
        /* opacity: 0.5; */
        color: #22c55e;
    }
    70% {
        -webkit-transform: scale(1.4, 1.4);

        opacity: 0;
    }
    100% {
        /* -webkit-transform: scale(1.5, 1.5); */
        opacity: 0;
    }
}
